import { digitalKeyBoardPattern } from '@utils/digitalKeyBoardPattern';
import React, { useEffect, useRef } from 'react';

import classes from './CodeInput.module.scss';

export interface CodeInputProps {
    onChange: (code: string) => void;
}

export const CodeInput = ({ onChange }: CodeInputProps) => {
    const ref = React.useRef<HTMLInputElement>(null);
    const focusNextInput = (e: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        if (ref && ref.current) {
            const form = ref.current.parentElement;

            if (form) {
                const selector = `.${classes.NumberInput}:nth-child(${currentIndex + 1}) input`;
                const item: HTMLElement | null = form.querySelector(selector);
                if (item) {
                    item.focus();
                }
            }
        }
    };

    const [val1, setVal1] = React.useState('');
    const [val2, setVal2] = React.useState('');
    const [val3, setVal3] = React.useState('');
    const [val4, setVal4] = React.useState('');

    useEffect(() => {
        const code = `${val1}${val2}${val3}${val4}`;
        if (code.length === 4) {
            onChange(code);
        } else {
            onChange('');
        }
    }, [val1, val2, val3, val4]);

    const firstInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        firstInput.current?.focus();
    }, []);

    return (
        <div ref={ref} className={classes.CodeInput}>
            <div className={classes.NumberInput}>
                <input
                    pattern={digitalKeyBoardPattern}
                    inputMode="numeric"
                    ref={firstInput}
                    onKeyDown={e => {
                        if (e.key === 'Backspace') {
                            setVal1('');
                        }
                    }}
                    onChange={item => {
                        const value = item.target.value;
                        const cleanedValue = value.replace(/\D/g, '');

                        if (cleanedValue.length === 4) {
                            setVal1(cleanedValue[0]);
                            setVal2(cleanedValue[1]);
                            setVal3(cleanedValue[2]);
                            setVal4(cleanedValue[3]);
                        } else {
                            setVal1(cleanedValue[0] || '');
                            if (cleanedValue !== '') {
                                focusNextInput(item, 1);
                            }
                        }
                    }}
                    type="text"
                    value={val1}
                />
            </div>
            <div className={classes.NumberInput}>
                <input
                    pattern={digitalKeyBoardPattern}
                    inputMode="numeric"
                    onKeyDown={e => {
                        if (e.key === 'Backspace') {
                            if (val2 === '') {
                                // @ts-ignore
                                focusNextInput(e, 0);
                            } else {
                                setVal2('');
                            }
                        }
                    }}
                    onChange={item => {
                        const value = item.target.value;
                        const cleanedValue = value.replace(/\D/g, '');
                        setVal2(cleanedValue[0] || '');
                        if (cleanedValue !== '') {
                            focusNextInput(item, 2);
                        }
                    }}
                    type="text"
                    maxLength={1}
                    value={val2}
                />
            </div>
            <div className={classes.NumberInput}>
                <input
                    pattern={digitalKeyBoardPattern}
                    inputMode="numeric"
                    onKeyDown={e => {
                        if (e.key === 'Backspace') {
                            if (val3 === '') {
                                // @ts-ignore
                                focusNextInput(e, 1);
                            } else {
                                setVal3('');
                            }
                        }
                    }}
                    onChange={item => {
                        const value = item.target.value;
                        const cleanedValue = value.replace(/\D/g, '');
                        setVal3(cleanedValue[0] || '');
                        if (cleanedValue !== '') {
                            focusNextInput(item, 3);
                        }
                    }}
                    type="text"
                    maxLength={1}
                    value={val3}
                />
            </div>
            <div className={classes.NumberInput}>
                <input
                    pattern={digitalKeyBoardPattern}
                    inputMode="numeric"
                    onKeyDown={e => {
                        if (e.key === 'Backspace') {
                            if (val4 === '') {
                                // @ts-ignore
                                focusNextInput(e, 2);
                            } else {
                                setVal4('');
                            }
                        }
                    }}
                    onChange={item => {
                        const value = item.target.value;
                        const cleanedValue = value.replace(/\D/g, '');
                        setVal4(cleanedValue[0] || '');
                    }}
                    type="text"
                    value={val4}
                    maxLength={1}
                />
            </div>
        </div>
    );
};
