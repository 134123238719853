import { HttpCode, MILISECONDS_IN_SECOND, TOKEN_TIME_DIFF_SECONDS } from '@scripts/constants';
import { getHost } from '@scripts/helpers';

import { LoginData, getAuthInfoFromLocalStorage, removeAuthInfo, updateAuthInfo } from './auth/helpers';
import { CommonResponse, Config, FetchError } from './common/types';
import { Customer, CustomerMutate } from './customers/types';

export class APIClient {
    baseURL: string;

    readonly authURL = 'auth';

    checkRequest: Promise<LoginData> | null = null;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    static checkAuthorization(response: Response) {
        if (response.status === HttpCode.UNAUTHORIZED) {
            removeAuthInfo();
            window.location.assign(window.location.href);
        }
    }

    static async returnJSON(response: Response) {
        APIClient.checkAuthorization(response);

        const json: CommonResponse<any> = await response.json();

        if (!response.ok) {
            let errorMessage = 'Request failed';
            let errorCode = '';
            /** we must throw errors to allow react-query catch them in hooks */
            if (json.errors && json.errors.length > 0) {
                errorMessage = json.errors.map(e => e.message).join(` \n`);
                errorCode = [...new Set(json.errors.map(e => e.code))].join(` & `);
            }
            throw new FetchError(errorMessage, response.status, errorCode);
        }
        return json;
    }

    static async returnBlob(response: Response) {
        APIClient.checkAuthorization(response);
        return response.blob();
    }

    protected async unauthorizedClient(
        endpoint: string,
        { data, token, timeout = 60000, headers: customHeaders = {}, params, ...customConfig }: Config = {}
    ) {
        const endpoinWithParams = `${endpoint}${params ? `?${new URLSearchParams(params)}` : ''}`;

        const controller = new AbortController();
        const timer = setTimeout(() => controller.abort(), timeout);

        const config = {
            method: data ? 'POST' : 'GET',
            // eslint-disable-next-line no-nested-ternary
            body: data
                ? typeof window !== 'undefined' && data instanceof FormData
                    ? data
                    : JSON.stringify(data)
                : undefined,
            headers: {
                ...(data &&
                    !(typeof window !== 'undefined' && data instanceof FormData) && {
                        'Content-Type': 'application/json',
                    }),
                ...(token && { Authorization: `Bearer ${token}` }),
                ...customHeaders,
            },
            ...customConfig,
            signal: controller.signal,
        };
        const response = await fetch(`${this.baseURL}${endpoinWithParams}`, config);
        clearTimeout(timer);

        return response;
    }

    protected async refreshToken(access_token: string, refresh_token: string): Promise<LoginData> {
        return this.unauthorizedClient(`${this.authURL}/refresh`, {
            data: { refresh_token },
            token: access_token,
        })
            .then(APIClient.returnJSON)
            .then(updateAuthInfo);
    }

    protected async checkToken() {
        let token = '';

        if (typeof window === 'undefined') return token;

        try {
            const timeNow = Math.floor(Date.now() / MILISECONDS_IN_SECOND);
            const { expires_at, refresh_token, access_token } = getAuthInfoFromLocalStorage();

            if (expires_at && refresh_token && +expires_at < timeNow - TOKEN_TIME_DIFF_SECONDS) {
                if (!this.checkRequest) {
                    this.checkRequest = this.refreshToken(access_token, refresh_token);
                }

                const result = await this.checkRequest;

                if (result) {
                    updateAuthInfo(result);
                    token = result.data.access_token;
                }

                this.checkRequest = null;
            } else if (access_token) {
                token = access_token;
            }
        } catch (e) {
            console.error(`Unable to check token: ${e}`);
        }

        return token;
    }

    public async request(endpoint: string, config?: Config) {
        const token = await this.checkToken();
        return this.unauthorizedClient(endpoint, { ...config, token }).then(APIClient.returnJSON);
    }

    public async get(endpoint: string, config?: Omit<Config, 'data'>) {
        return this.request(endpoint, { ...config, method: 'GET' });
    }

    public async post(endpoint: string, config?: Config) {
        return this.request(endpoint, { ...config, method: 'POST' });
    }

    public async patch(endpoint: string, config?: Config) {
        return this.request(endpoint, { ...config, method: 'PATCH' });
    }

    public async put(endpoint: string, config?: Config) {
        return this.request(endpoint, { ...config, method: 'PUT' });
    }

    public async delete(endpoint: string, config?: Config) {
        return this.request(endpoint, { ...config, method: 'DELETE' });
    }

    public async downloadFile(endpoint: string, config?: Config) {
        const token = await this.checkToken();
        return this.unauthorizedClient(endpoint, { ...config, token, method: 'POST' }).then(APIClient.returnBlob);
    }

    public async logIn({ login, password }: { login: string; password: string }): Promise<LoginData> {
        return this.unauthorizedClient(`${this.authURL}/login`, {
            data: { login, password },
        })
            .then(APIClient.returnJSON)
            .then(updateAuthInfo);
    }

    public async logInCodeByPhone({ phone, code }: { phone: string; code: string }): Promise<LoginData> {
        return this.unauthorizedClient(`${this.authURL}/login:by-phone`, {
            data: { phone, code },
        })
            .then(APIClient.returnJSON)
            .then(updateAuthInfo);
    }

    public async logInCodeByEmail({ email, code }: { email: string; code: string }): Promise<LoginData> {
        return this.unauthorizedClient(`${this.authURL}/login:by-email`, {
            data: { email, code },
        })
            .then(APIClient.returnJSON)
            .then(updateAuthInfo);
    }
    public async registrationByCode({
        timezone,
        phone,
        email,
        type,
        code,
        inn,
        ogrn,
        okato,
        okfs,
        okpo,
        oktmo,
        okved,
        org_name_full,
        org_info,
        org_name,
    }: CustomerMutate): Promise<LoginData> {
        if (typeof window.ym !== undefined && typeof window.ym === 'function') {
            window.ym(92835880, 'reachGoal', 'new_registration');
        }
        return this.unauthorizedClient(`customers/register:by-code`, {
            data: {
                timezone,
                phone,
                email,
                type,
                code,
                inn,
                ogrn,
                okato,
                okfs,
                okpo,
                oktmo,
                okved,
                org_name_full,
                org_info,
                org_name,
            },
        })
            .then(APIClient.returnJSON)
            .then(updateAuthInfo);
    }

    public async logOut() {
        return this.request(`${this.authURL}/logout`).then(removeAuthInfo);
    }
}

export const apiClient = new APIClient(`${getHost()}/api/v1/`);
export const apiFront = new APIClient('/api/api-front/');
export const apiAnyClient = new APIClient('https://any-collections.diginetica.net/api/sites/');
export const apiAnyQuery = new APIClient('https://sort.diginetica.net/');

export { FetchError };
