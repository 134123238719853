import { CSSObject } from '@emotion/core';
import { phoneFormat } from '@utils/phoneFormat';
import { FormikValues } from 'formik';
import Link from 'next/link';
import { FC, useEffect } from 'react';

import {
    useCheckoutByEmail,
    useCheckoutByPhone,
    useVerificationCodeByEmail,
    useVerificationCodeByPhone,
} from '@api/auth';

import Form from '@controls/Form';

import Loader from '@components/controls/Loader';

import { Button, Layout, colors, scale, typography } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import CrossIcon from '@icons/clean-up-cross.svg';

interface SignInFormProps {
    logIn: (params: { phone: string; code: string }) => void;
    closeHandler: () => void;
    errorMessage: string;
    setPhone: (arg: string) => void;
    onChangeToRegistration: () => void;
    setEmail: (arg: string) => void;
    onChangeEmailToLogin: () => void;
    onChangePhoneToLogin: () => void;
    setIsLogin: (arg: boolean) => void;
    setErrorMessage: (arg: string) => void;
    path?: string;
}

export const SignIn: FC<SignInFormProps> = ({
    logIn,
    setEmail,
    closeHandler,
    errorMessage,
    setPhone,
    onChangeToRegistration,
    onChangeEmailToLogin,
    onChangePhoneToLogin,
    setIsLogin,
    setErrorMessage,
    path,
}) => {
    const { xs } = useMedia();

    const errorStyles: CSSObject = {
        fontSize: '14px',
        background: colors.backgroundRed,
        borderRadius: scale(1, true),
        padding: `${scale(1)}px ${scale(2)}px`,
        color: colors.actionRed,
        margin: `8px 0`,

        [xs]: {
            margin: `8px 0 0`,
        },
    };

    const {
        mutateAsync: verificationCodeByPhone,
        isLoading: isLoadingVerificationPhone,
        error: errorVerificationByPhone,
    } = useVerificationCodeByPhone();

    const {
        mutateAsync: verificationCodeByEmail,
        isLoading: isLoadingVerificationByEmail,
        error: errorVerificationByEmail,
    } = useVerificationCodeByEmail();
    const {
        mutateAsync: checkoutByPhone,
        isLoading: isLoadingCheckoutPhone,
        error: errorCheckoutPhone,
    } = useCheckoutByPhone();
    const {
        mutateAsync: checkoutByEmail,
        isLoading: isLoadingCheckoutEmail,
        error: errorCheckoutEmail,
    } = useCheckoutByEmail();

    const isLoadingCheckout = isLoadingCheckoutPhone || isLoadingCheckoutEmail;
    const isLoadingVerification = isLoadingVerificationPhone || isLoadingVerificationByEmail;

    useEffect(() => {
        return () => setErrorMessage('');
    }, []);

    return (
        <Form
            onSubmit={async ({ phone }) => {
                setErrorMessage('');
                if (phone.includes('@')) {
                    try {
                        const result = await checkoutByEmail({ email: phone });
                        if (result.data && result.data.found) {
                            setEmail(phone);
                            const result = await verificationCodeByEmail({ email: phone });
                            setIsLogin(true);
                            onChangeEmailToLogin();
                        } else {
                            setEmail(phone);
                            setIsLogin(false);
                            setErrorMessage('Пользователя с таким email не существует');
                        }
                    } catch (error: any) {
                        setErrorMessage(error.message);
                    }
                } else {
                    try {
                        const changedPhone = phoneFormat(phone);
                        const result = await checkoutByPhone({ phone: changedPhone });

                        if (result.data && result.data.found) {
                            setPhone(changedPhone);
                            const result = await verificationCodeByPhone({ phone: changedPhone });

                            setIsLogin(true);
                            onChangePhoneToLogin();
                        } else {
                            setPhone(changedPhone);
                            setIsLogin(false);
                            setErrorMessage('Пользователя с таким номером не существует');
                        }
                    } catch (error: any) {
                        setErrorMessage(error.message);
                    }
                }
            }}
            initialValues={{ phone: '', rememberMe: false }}
        >
            {({ values: { phone }, errors: { phone: phoneError }, setFieldValue }): FormikValues => {
                return (
                    <>
                        <Layout cols={1} gap={scale(5, true)} css={{ ...typography('inputFields') }}>
                            <Layout.Item>
                                <Form.Field
                                    autoFocus
                                    isLegend
                                    name="phone"
                                    theme="primary"
                                    isLoading={isLoadingCheckout}
                                    iconClick={() => {
                                        setFieldValue('phone', '');
                                        setErrorMessage('');
                                    }}
                                    Icon={phone && CrossIcon}
                                    iconCss={{ cursor: 'pointer' }}
                                    placeholder="Номер телефона или email"
                                    messageType="error"
                                    errors={!!errorMessage}
                                    label={
                                        <div
                                            css={{
                                                fontFamily: 'var(--font-roboto)',
                                                fontWeight: 400,
                                                fontSize: '13px',
                                                lineHeight: '20.8px',
                                                padding: '0 16px',
                                                marginBottom: '4px',
                                                color: '#4A5661',
                                            }}
                                        >
                                            Введите номер телефона или email
                                        </div>
                                    }
                                    css={{
                                        fontFamily: 'var(--font-roboto)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'end',
                                        height: '82px',
                                        fontSize: '14px',
                                        color: '#4A5661',
                                    }}
                                />
                            </Layout.Item>
                        </Layout>

                        {errorMessage && (
                            <div css={errorStyles}>{errorMessage || phoneError || errorCheckoutPhone?.message}</div>
                        )}

                        <Button
                            type="submit"
                            block
                            theme="secondary"
                            size="md"
                            data-testid="button-submit"
                            role="button"
                            disabled={!phone}
                            css={{ marginTop: '24px', position: 'relative' }}
                        >
                            <span css={{ position: 'relative' }}>
                                Получить код{' '}
                                {isLoadingVerification && (
                                    <Loader
                                        height={24}
                                        width={24}
                                        css={{
                                            position: 'absolute',
                                            right: scale(1),
                                            top: '50%',
                                            left: '120%',
                                            transform: 'translateY(-50%)',
                                        }}
                                    />
                                )}
                            </span>
                        </Button>
                        {path && (
                            <div css={{ display: 'none', [xs]: { display: 'block', marginTop: '20px' } }}>
                                <Link legacyBehavior href="/" passHref prefetch={false}>
                                    <Button
                                        as="a"
                                        theme="ghostSecondary"
                                        size="navigation"
                                        data-testid="button-go-back"
                                    >
                                        Вернуться к товарам
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </>
                );
            }}
        </Form>
    );
};
